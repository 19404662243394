define('mandateField',["knockout", "helperFunctions", "knockout.validation"],
    function (ko, helpers) {
        return function (params) {
            var self = this;

            self.isbeneficiaryCountryCode = false;
            self.options = ko.observableArray(params.schemaField.options);
            self.activeOption = ko.observable();

            self.field = params.schemaField;
            self.activeFields = params.activeFields;
            self.feedback = params.feedback;

            self.feedbackSub = undefined;
            self.countries = params.countries;

            self.label = self.field.isRequired ? self.field.title + ' *' : self.field.title;

            if (ko.isObservable(params.model)) {
                self.model = params.model();
            } else {
                self.model = params.model;
            }

            self.setActive = function (field) {
                self.activeOption(field.name);
            };

            self.feedbackStatus = function (modelName) {
                return self.feedback[modelName]["status"]();
            };

            self.activeOption.subscribe(function (oldValue) {
                if (oldValue === undefined) {
                    return;
                }

                if (self.activeFields.hasOwnProperty(self.activeOption())) {
                    self.activeFields[self.activeOption()]["status"](false);
                    if (self.model[self.activeOption()].hasProperty(rules)) {
                        ko.validation.group(self.model[self.activeOption()]);
                    }
                }
            }, null, "beforeChange");

            self.activeOption.subscribe(function (newValue) {
                if (!self.activeFields[newValue]) {
                    return;
                }

                self.activeFields[newValue]["status"](true);
                if (self.model[self.activeOption()].rules) {
                    ko.validation.group(self.model[self.activeOption()]);
                }
            });

            var onLoad = function () {
                if (self.model[self.field["name"]] !== undefined) {

                    self.isbeneficiaryCountryCode = self.field.name.toLowerCase() === 'beneficiarycountrycode';

                    self.feedbackSub = self.model[self.field["name"]].subscribe(function () {
                        self.feedback[self.field["name"]]["status"](true);
                        self.feedbackSub.dispose();
                    });
                }

                if (self.field.hasOwnProperty("activeOption") &&
                    self.field["activeOption"]()) {
                    self.activeOption(self.field["activeOption"]());

                    self.activeOption.subscribe(function (oldValue) {

                        helpers.setParentAndChildActiveFieldStatus(
                                oldValue,
                                self.activeFields[self.field.name]["activeFields"][oldValue],
                                false);

                        ko.validation.validateObservable(self.model[self.field.name]()[oldValue]);
                    }, null, "beforeChange");

                    self.activeOption.subscribe(function (newValue) {
                        helpers.setParentAndChildActiveFieldStatus(
                                newValue,
                                self.activeFields[self.field.name]["activeFields"][newValue],
                                true);

                        self.field["activeOption"](newValue);
                        ko.validation.validateObservable(self.model[self.field.name]()[newValue]);
                    });
                }
            };

            onLoad();
        };
    });
