(function (b) {
    var c = {
        allowFloat: false,
        allowNegative: false,
        disableCopyOnDecimal: false
    };
    b.fn.numericInput = function (e) {
        var f = b.extend({}, c, e);
        var d = f.allowFloat;
        var g = f.allowNegative;
        var z = f.disableCopyOnDecimal;

        this.keypress(function (j) {
            var i = j.which;
            //if the user is copy pasting content [ctrl + v/V/c/C/a/A -> ]
            if (j.ctrlKey && (i === 86 || i === 118 || i === 97 || i === 65 || i === 67 || i === 99))
                return true;
            var h = b(this).val();
            if (i > 0 && (i < 48 || i > 57)) {
                if (d == true && i == 46) {
                    if (g == true && a(this) == 0 && h.charAt(0) == "-") {
                        return false
                    }
                    if (h.match(/[.]/)) {
                        return false
                    }
                } else {
                    if (g == true && i == 45) {
                        if (h.charAt(0) == "-") {
                            return false
                        }
                        if (a(this) != 0) {
                            return false
                        }
                    } else {
                        if (i == 8) {
                            return true
                        } else {
                            return false
                        }
                    }
                }
            } else {
                if (i > 0 && (i >= 48 && i <= 57)) {
                    if (g == true && h.charAt(0) == "-" && a(this) == 0) {
                        return false
                    }
                }
            }
        });

        this.on("paste", (function (j) {
            var h = b(this).val();
            //get clipboard data and disable paste if the text has "." for positive whole numbers
            var t = j.originalEvent.clipboardData ? j.originalEvent.clipboardData.getData("Text") : window.clipboardData.getData("Text"); //fallback for IE
            if (z && typeof h == "string" && t.indexOf(".") > -1)
                j.preventDefault();
        }));

        return this
    };


    function a(d) {
        if (d.selectionStart) {
            return d.selectionStart
        } else {
            if (document.selection) {
                d.focus();
                var f = document.selection.createRange();
                if (f == null) {
                    return 0
                }
                var e = d.createTextRange(),
                    g = e.duplicate();
                e.moveToBookmark(f.getBookmark());
                g.setEndPoint("EndToStart", e);
                return g.text.length
            }
        }
        return 0
    }
}(jQuery));
define("numericInput", ["jquery"], (function (global) {
    return function () {
        var ret, fn;
        return ret || global.jQuery;
    };
}(this)));

