define('helperFunctions',["knockout"], function (ko) {
    var Helpers = function () {
        var self = this;

        self.oneOfRequiredGroup = ["oneOf"];

        self.isNullOrEmpty = function (field) {
            if (typeof field === "object") {
                for (var prop in field) {
                    if (field.hasOwnProperty(prop) &&
                        ko.isObservable(field[prop])) {
                        if (!self.isNullOrEmpty(field[prop]())) {
                            return false;
                        }
                    }
                }

                return true;
            } else {
                return field === undefined || field === "" ? true : false;
            }
        };

        self.buildResponseMessage = function (model, activeFields) {
            var obj = {};

            for (var param in activeFields) {
                if (activeFields.hasOwnProperty(param) &&
                    activeFields[param]["status"]()) {
                    if (activeFields[param].hasOwnProperty("activeFields")) {
                        if (!activeFields[param].isSchemaModel) {
                            return self.buildResponseMessage(model[param](), activeFields[param]["activeFields"]);
                        } else {
                            obj[param] =
                                self.buildResponseMessage(model[param](), activeFields[param]["activeFields"]);
                        }
                    } else {
                        obj[param] = model[param]();
                    }
                }
            }

            return obj;
        };

        self.buildJsonFieldListResponseMessage = function (model, countryCode, currencyCode) {
            var obj = {};

            for (var field in model) {
                obj[field] = model[field]();
            }
            
            return obj;
        };

        self.setAllFeedbackToTrue = function (feedback) {
            for (var field in feedback) {
                if (feedback[field].hasOwnProperty("status")) {
                    feedback[field]["status"](true);
                }
                if (feedback[field].hasOwnProperty("feedback")) {
                    self.setAllFeedbackToTrue(feedback[field]["feedback"]);
                }
            }
        };

        var setChildActiveFieldsStatus = function (activeFields, newStatus) {
            for (var prop in activeFields) {
                if (activeFields.hasOwnProperty(prop)) {
                    activeFields[prop]["status"](newStatus);
                    if (activeFields[prop].hasOwnProperty("activeFields")) {
                        setChildActiveFieldsStatus(activeFields[prop]["activeFields"]);
                    }
                }
            }
        };

        self.setParentAndChildActiveFieldStatus = function (parentName, activeFields, newStatus) {
            activeFields["status"](newStatus);
            setChildActiveFieldsStatus(activeFields["activeFields"], newStatus);
        };

        var getRequiredField = function (propertyName, requiredFields) {
            if (!requiredFields) {
                return false;
            }

            for (var i = 0; i < requiredFields.length; i++) {
                if (propertyName === requiredFields[i]) {
                    return true;
                }
            }

            return false;
        };

        self.fieldRequired = function (propertyName, requiredFields, activeFields) {
            return activeFields[propertyName]["status"]() && getRequiredField(propertyName, requiredFields);
        };

        self.groupIsOptionalAndEmpty = function (propertyName, requiredFields, childrenModel, activeFields) {
            if (!activeFields[propertyName]["status"]()) {
                return true;
            }

            for (var child in childrenModel) {
                if (childrenModel.hasOwnProperty(child) &&
                    ko.isObservable(childrenModel[child])) {
                    if (!self.isNullOrEmpty(childrenModel[child]())) {
                        return false;
                    }
                }
            }

            if (requiredFields === self.oneOfRequiredGroup ||
                getRequiredField(propertyName, requiredFields)) {
                return false;
            }

            return true;
        };

        self.getOneOfChildrenRequiredFields = function (activeStatus, propertyName, parentRequiredFields) {
            return activeStatus && getRequiredField(propertyName, parentRequiredFields) ?
                self.oneOfRequiredGroup : [];
        };


        var processServerError = function (error, model, errorList) {
            var fieldName = "";
            var fieldTitle = "";

            if (error.hasOwnProperty("FieldName")) {
                fieldName = error["FieldName"];
                fieldTitle = fieldName;
            }

            if (error.hasOwnProperty("FieldTitle")) {
                fieldTitle = error["FieldTitle"];
            }

            if (!model.hasOwnProperty(fieldName)) {
                errorList.push(fieldTitle + " - " + error["ErrorMessage"]);
            }
        };

        self.getNonFieldErrors = function (model, serverErrors) {
            var errors = [];
            for (var outerIndex in serverErrors) {
                if (serverErrors.hasOwnProperty(outerIndex)) {
                    processServerError(serverErrors[outerIndex], model, errors);
                }
            }

            return errors;
        }
    };

    return new Helpers();
});

