define('customValidationRules',["knockout", "helperFunctions", "knockout.validation"],
    function (ko, helpers) {
        var CustomValidationRules =
            function () {
                var oneOfIsValid = function (childrenModel, groupIsRequired, activeOption) {
                    if (childrenModel.hasOwnProperty(activeOption) &&
                        ko.isObservable(childrenModel[activeOption])) {

                        if (helpers.isNullOrEmpty(childrenModel[activeOption]()) &&
                            !groupIsRequired) {
                            return true;
                        }

                        if (!helpers.isNullOrEmpty(childrenModel[activeOption]())) {
                            if(!childrenModel[activeOption].isValid()) {
                                return false;
                            }

                            if (childrenModel[activeOption].isValid()) {
                                return true;
                            }
                        }

                    }

                    return false;
                };

                ko.validation.rules["oneOfIsValid"] = {
                    validator: function (val, options) {
                        return oneOfIsValid(options.childrenModel, options.groupRequired(), options.activeOption());
                    },
                    message: ""
                };

                ko.validation.rules["childrenValid"] = {
                    validator: function (val, options) {
                        if (options.groupIsOptionalAndEmpty()) {
                            return true;
                        }

                        for (var child in options.childrenModel) {
                            if (options.childrenModel.hasOwnProperty(child) &&
                                ko.isObservable(options.childrenModel[child])) {
                                if (!options.childrenModel[child].isValid()) {
                                    return false;
                                }
                            }
                        }

                        return true;
                    },
                    message: ""
                };
            };

        return new CustomValidationRules();
    });
