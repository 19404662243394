define('schemaFieldCreation',["knockout", "helperFunctions", "knockout.validation"], function (ko, helpers) {
    return function (model, activeFields, feedback, schema, schemaFields) {
        var self = this;

        self.schema = schema;
        self.model = model;
        self.activeFields = activeFields;
        self.feedback = feedback;
        self.schemaFields = schemaFields;

        var createSchemaFieldsFromProperties;

        var createValidationRulesFrom = function (propertyName, propertyDetails, requiredFields, activeFields) {
            var rules = {};

            rules["required"] = {
                onlyIf: function () {
                    return helpers.fieldRequired(propertyName, requiredFields, activeFields);
                },
                message: propertyName + " is required"
            };

            if (propertyDetails.hasOwnProperty("minLength")) {
                rules["minLength"] = {
                    onlyIf: function () {
                        return activeFields[propertyName]["status"]();
                    },
                    params: propertyDetails["minLength"]
                };
            }

            if (propertyDetails.hasOwnProperty("maxLength")) {
                rules["maxLength"] = {
                    onlyIf: function () {
                        return activeFields[propertyName]["status"]();
                    },
                    params: propertyDetails["maxLength"]
                };
            }

            if (propertyDetails.hasOwnProperty("pattern")) {
                rules["pattern"] = {
                    onlyIf: function () {
                        return activeFields[propertyName]["status"]();
                    },
                    params: propertyDetails["pattern"]
                };
            }

            return rules;
        };

        var createObjectValidationRulesFrom = function (propertyName, childrenModel, requiredFields, activeFields) {
            var rules = {};

            rules["childrenValid"] = {
                childrenModel: childrenModel,
                groupIsOptionalAndEmpty: function () {
                    return helpers.groupIsOptionalAndEmpty(propertyName, requiredFields, childrenModel, activeFields);
                }
            };

            return rules;
        };

        var createOneOfValidationRulesFrom = function (propertyName, childrenModel, requiredFields, activeFields, activeOption) {
            var rules = {};
            rules["oneOfIsValid"] = {
                childrenModel: childrenModel,
                groupRequired: function () {
                    return helpers.fieldRequired(propertyName, requiredFields, activeFields);
                },
                activeOption: activeOption
            };

            return rules;
        };

        var createSchemaField = function (parentField, parentModel, activeFields, feedback, propertyName, propertyDetails, requiredFields, activeStatus, isSchemaModel) {
            var field = {};
            field["name"] = propertyName;
            field["type"] = propertyDetails.type;
            field["description"] = propertyDetails.description;

            if (propertyDetails.hasOwnProperty("title") &&
                propertyDetails["title"]) {
                field["title"]= propertyDetails["title"];
                field["showLabel"]= true;
            } else {
                field["title"] = null;
                field["showLabel"]= false;
            }

            field["isRequired"] = ko.utils.arrayIndexOf(requiredFields,propertyName) > -1;

            activeFields[propertyName] = {
                "status": ko.observable(activeStatus),
                "isSchemaModel": isSchemaModel
            };

            var validationRules = createValidationRulesFrom(propertyName, propertyDetails, requiredFields, activeFields);
            feedback[propertyName] = { "status": ko.observable(false) };
            parentModel[propertyName] = ko.observable().extend(validationRules);

            return ko.observable(field);
        };

        var createSchemaObject = function (parentField, parentModel, activeFields, feedback, propertyName, propertyDetails, parentRequiredFields, objectRequiredFields, activeStatus, isSchemaModel) {
            var obj = {};
            obj["name"] = propertyName;
            obj["type"] = propertyDetails.type;
            obj["description"] = propertyDetails.description;

            if (propertyDetails.hasOwnProperty("title") &&
                propertyDetails["title"]) {
                obj["title"] = propertyDetails["title"];
                obj["showLabel"] = true;
            } else {
                obj["title"]= null;
                obj["showLabel"] = false;
            }

            var childrenModel = {};
            var childrenActiveFields = {};
            var childrenFeedback = {};

            obj["childFields"] = ko.observableArray([]);
            if (propertyDetails.hasOwnProperty("properties")) {
                createSchemaFieldsFromProperties(
                    obj["childFields"],
                    childrenModel,
                    childrenActiveFields,
                    childrenFeedback,
                    propertyDetails["properties"],
                    objectRequiredFields,
                    null,
                    function () { return activeStatus; },
                    true);
            }

            activeFields[propertyName] = {
                "status": ko.observable(activeStatus),
                "isSchemaModel": isSchemaModel,
                "activeFields": childrenActiveFields
            };
            feedback[propertyName] = {
                "status": ko.observable(false),
                "feedback": childrenFeedback
            };

            obj["setGroupActiveStatus"] = ko.computed(function () {
                var groupIsOptionalAndEmpty = helpers.groupIsOptionalAndEmpty(propertyName, parentRequiredFields, childrenModel, activeFields);
                for (var child in childrenModel) {
                    if (childrenActiveFields.hasOwnProperty(child)) {
                        if (groupIsOptionalAndEmpty) {
                            childrenActiveFields[child]["status"](false);
                        } else {
                            childrenActiveFields[child]["status"](true);
                        }

                        ko.validation.validateObservable(childrenModel[child]);
                    }
                }

                return true;
            });

            var validationRules = createObjectValidationRulesFrom(propertyName, childrenModel, parentRequiredFields, activeFields);
            parentModel[propertyName] = ko.observable(childrenModel).extend(validationRules);

            return ko.observable(obj);
        };

        var createSchemaOneOf = function (parentField, parentModel, activeFields, feedback, propertyName, properties, parentRequiredFields, activeStatus, isSchemaModel) {

            var childOptions = properties["oneOf"];

            var childOptionNames = [];
            for (var childOption in childOptions) {
                if (childOptions.hasOwnProperty(childOption)) {
                    childOptionNames.push(childOptions[childOption]["title"]);
                }
            }

            var oneOf = {
                "name": propertyName,
                "type": "oneOf",
                "activeOption": ko.observable(childOptionNames[0])
            };

            var options = [];
            var childrenModel = {};
            var childrenActiveFields = {};
            var childrenFeedback = {};
            var childrenRequiredFields = helpers.getOneOfChildrenRequiredFields(activeStatus, propertyName, parentRequiredFields);

            var i = 0;
            createSchemaFieldsFromProperties(
                options,
                childrenModel,
                childrenActiveFields,
                childrenFeedback,
                childOptions,
                childrenRequiredFields,
                function () {
                    return childOptionNames[i++];
                },
                function () {
                    return i === 0 ? true : false;
                },
                false
            );

            oneOf["options"] = options;

            activeFields[propertyName] = {
                "status": ko.observable(activeStatus),
                "isSchemaModel": isSchemaModel,
                "activeFields": childrenActiveFields
            };
            feedback[propertyName] = {
                "status": ko.observable(false),
                "feedback": childrenFeedback
            };

            var validationRules = createOneOfValidationRulesFrom(propertyName, childrenModel, parentRequiredFields, activeFields, oneOf.activeOption);
            parentModel[propertyName] = ko.observable(childrenModel).extend(validationRules);

            return ko.observable(oneOf);
        };

        var propertyIsPresentWithTypeAndIsNotCountryOrCurrency = function (properties, propertyName) {
            if (properties.hasOwnProperty(propertyName) &&
                propertyName.toLowerCase() !== "countrycode" &&
                propertyName.toLowerCase() !== "currencycode" &&
                properties[propertyName].hasOwnProperty("type")) {
                return true;
            }

            return false;
        };

        var propertyIsPresentWithOneOf = function (properties, propertyName) {
            if (properties.hasOwnProperty(propertyName) &&
                properties[propertyName].hasOwnProperty("oneOf")) {
                return true;
            }

            return false;
        };

        createSchemaFieldsFromProperties = function (parentField, parentModel, activeFields, feedback, properties, parentRequiredFields, overrideName, activeStatus, isSchemaModel) {
            var fieldName;
            var fieldActive;

            // ReSharper disable once MissingHasOwnPropertyInForeach
            for (var propertyName in properties) {

                if (propertyName !== "additionalBanking") {//Additional banking details are not required

                    if (typeof activeStatus === "function") {
                        fieldActive = activeStatus();
                    } else {
                        fieldActive = true;
                    }

                    if (typeof overrideName === "function") {
                        fieldName = overrideName();
                    } else {
                        fieldName = propertyName;
                    }

                    if (propertyIsPresentWithTypeAndIsNotCountryOrCurrency(properties, propertyName)) {

                        switch (properties[propertyName].type) {
                            case "string":
                                parentField.push(
                                    createSchemaField(
                                        parentField,
                                        parentModel,
                                        activeFields,
                                        feedback,
                                        fieldName,
                                        properties[propertyName],
                                        parentRequiredFields,
                                        fieldActive,
                                        isSchemaModel));
                                break;
                            case "object":
                                parentField.push(
                                    createSchemaObject(
                                        parentField,
                                        parentModel,
                                        activeFields,
                                        feedback,
                                        fieldName,
                                        properties[propertyName],
                                        parentRequiredFields,
                                        properties[propertyName].required,
                                        fieldActive,
                                        isSchemaModel));
                                break;
                            default:
                                break;
                        }

                        continue;
                    }

                    if (propertyIsPresentWithOneOf(properties, propertyName)) {
                        parentField.push(
                            createSchemaOneOf(
                                parentField,
                                parentModel,
                                activeFields,
                                feedback,
                                fieldName,
                                properties[propertyName],
                                parentRequiredFields,
                                fieldActive,
                                isSchemaModel));
                        continue;
                    }
                }
            }
        };

        self.createSchemaFields = function () {
            if (self.schema().hasOwnProperty("properties")) {
                createSchemaFieldsFromProperties(self.schemaFields, self.model, self.activeFields, self.feedback, self.schema().properties, self.schema().required, null, null, true);
            }
        };

    };
});
