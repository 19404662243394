requirejs.config({
    urlArgs: "bust=" + (new Date()).getTime(),
    paths: {
        'text': "../Scripts/lib/require/text",
        'durandal': "../Scripts/lib/durandal/js",
        'plugins': "../Scripts/lib/durandal/js/plugins",
        'transitions': "../Scripts/lib/durandal/js/transitions",
        'knockout': "../Scripts/lib/knockout/knockout-3.4.0.debug",
        'bootstrap': "../Scripts/lib/bootstrap/js/bootstrap",
        'jquery': "../Scripts/lib/jquery/jquery-1.9.1",
        'moment': "../Scripts/lib/moment/moment-with-locales",
        'knockout.validation': "../Scripts/lib/knockout.validation",
        'numericInput': "../Scripts/lib/NumericInput.script",
        'datepicker': "../Scripts/lib/bootstrap-datetimepicker",
        'disablehtml': "../Scripts/lib/disable-html-tag/disable-html-tag",
        'jquery.mask': "../Scripts/lib/jquery-mask/jquery.mask",
        'scriptLoader': "../Scripts/scriptLoader",
        "mandateComponent": "../Scripts/lib/wireValidation/mandateComponent",
        "mandatePanel": "../Scripts/lib/wireValidation/mandatePanel",
        "mandateField": "../Scripts/lib/wireValidation/mandateField",
        "schemaFieldCreation": "../Scripts/lib/wireValidation/schemaFieldCreation",
        "customValidationRules": "../Scripts/lib/wireValidation/customValidationRules",
        "helperFunctions": "../Scripts/lib/wireValidation/helperFunctions",
        'jquery.ui.widget': '../Scripts/lib/blueimp-file-upload/js/vendor/jquery.ui.widget',
        'jquery.fileupload': '../Scripts/lib/blueimp-file-upload/js/jquery.fileupload',
        'jquery.fileupload-image': '../Scripts/lib/blueimp-file-upload/js/jquery.fileupload-image',
        'jquery.fileupload-process': '../Scripts/lib/blueimp-file-upload/js/jquery.fileupload-process',
        'jquery.fileupload-validate':'../Scripts/lib/blueimp-file-upload/js/jquery.fileupload-validate',
        'load-image': '../Scripts/lib/blueimp-load-image/load-image',
        'load-image-meta': '../Scripts/lib//blueimp-load-image/load-image-meta',
        'load-image-scale': '../Scripts/lib//blueimp-load-image/load-image-scale',
        'load-image-exif': '../Scripts/lib//blueimp-load-image/load-image-exif',
        'canvas-to-blob': '../Scripts/lib/blueimp-canvas-to-blob/canvas-to-blob',
        'components/fileUpload': '../app/components/fileUpload'
    },
    shim: {
        'bootstrap': {
            deps: ["jquery"],
            exports: "jQuery"
        },
        'numericInput': {
            deps: ["jquery"],
            exports: "jQuery"
        }
    },

});

define('main',["durandal/system",
    "durandal/app",
    "knockout",
    "bindings/bindings", "services/networkApi", "services/data",
    "services/isMobile",
    "services/CommonHelper",
    "services/accountService",    
    "services/sessionService",
    "plugins/router",
    "knockout.validation",
    "numericInput",
    "components/extenders",
    "validators/validators",
    "validations/validations",
    "mandateComponent",
    "mandatePanel",
    "mandateField",
    "components/components",
    "components/secondaryMenu",
    "bootstrap",
    "jquery.mask",
    "services/userPermittedActionService",
    "services/bannerService",
    'jquery.fileupload-image',
    'jquery.fileupload-validate'
],
function (system, app, ko, bindings, netWorkApi, dataService, isMobile, CommonHelper, accountService, sessionService, logging, router) {
    //>>excludeStart("build", true);
    system.debug(true);
    //>>excludeEnd("build");

    app.title = "DPS";

    app.configurePlugins({
        router: true,
        dialog: true
    });

    ko.validation.init(
    {
        insertMessages: false,
        grouping:
        {
            deep: true
        },
        decorateElement: true,
        errorElementClass: "has-error",
        messagesOnModified: true,
        errorMessageClass: "error-message",
        allowHtmlMessages:true
    });

    ko.validation.makeBindingHandlerValidatable("safeValue");
    ko.validation.registerExtenders();

    $("body").on("paste", ".no-paste", function (e) {
        e.preventDefault();
    });

    if (isMobile.im.apple.device) {
        CommonHelper.setCursorToPointerWhenUsingPopOverForIos();
    }

    sessionService.default.init();
    accountService.default.addHeaderFromHiddenInput();

    $(document).ajaxError(function (ev, xhr, settings, error) {
        switch (xhr.status) {
            case 403: {
                var redirectRoute = 'forbidden';
                if (xhr.responseText) {
                    redirectRoute = redirectRoute + '/' + xhr.responseText;
                }
                sessionService.default.clear();
                router.navigate(redirectRoute);
                break;
            }
            case 422:
                app.showMessage("An error occurred - please ensure you are not entering special characters such as < or >", "Error");
                break;
        }
    });


    app.start()
        .then(function () {
            var cacheQuery = netWorkApi.Account.getCache();

            cacheQuery.done(function (data) {
                dataService.update(data);
            });

            return cacheQuery;

        })
        .then(function () {
            app.setRoot("shell", "entrance");
        });
});
