//All API calls should be replaced with networkApi
define('mandateComponent',['knockout', 'helperFunctions', "./services/networkApi", 'knockout.validation'], function (ko, helpers, networkApi) {
    return function mandateComponent(params) {
        var self = this;

        self.swiftModel = params.swiftModel;
        self.setValidateSwiftBankDetails = params.setValidateSwiftBankDetails;
        self.countriesNameWithCode = params.swiftModel.countriesNameWithCode;
        self.result=ko.observable("");
        //clearing other tab details
        
        if (self.swiftModel.wireValidationModel.hasOwnProperty('beneficiaryBankAccount')
            && ko.isObservable(self.swiftModel.schemaFields)
            && self.swiftModel.schemaFields().length === 1
            && self.swiftModel.schemaFields()[0]().hasOwnProperty('activeOption')) {
            

            var activeOption = self.swiftModel.schemaFields()[0]().activeOption();
                        
        var modelWithData = self.swiftModel.wireValidationModel.beneficiaryBankAccount();

            var  optionNames = Object.keys(modelWithData);


            ko.utils.arrayForEach(optionNames, function (optionName) {

                if (activeOption !== optionName
                    && modelWithData.hasOwnProperty(optionName)
                    && ko.isObservable(modelWithData[optionName])
                    && typeof modelWithData[optionName]() === 'object') {

                    var currentOption = modelWithData[optionName]();

                    var fieldNames = Object.keys(currentOption);

                    ko.utils.arrayForEach(fieldNames, function (fieldName) {

                        if (ko.isObservable(currentOption[fieldName])) {
                            currentOption[fieldName](undefined);
                        }
                    });


                }

            });
        }
        self.selectedLanguage = ko.observable("");
        self.languages = ["en"];

        self.countries = ko.observableArray([]);
             
        var isEdit = (self.swiftModel.country() !== '');

        self.IsEditMode = ko.observable(isEdit);

        var isEditComplete = null;

        if (isEdit) {
            isEditComplete = false;
        }

        self.IsEditMode.subscribe(function (newValue) {
            if (!newValue) {
                isEditComplete = true;
            }
        });
      

        self.getSchemaFromAPI = function (params, successCallback) {
            const dto = {
                countryCode: self.swiftModel.country()
            };
            networkApi.Payments.getPaymentMandateSchema(dto).then(successCallback, function () {
                this.actionError(Resources.unknown_error);
            });
        };
        

        self.getSchema = ko.computed(function () {           
            if (!self.swiftModel.country() ) {
                
                return;
            }            
            self.result(undefined);

            self.swiftModel.isSwiftPaymentDetailsValid(false);

            
            if (isEditComplete === null || isEditComplete) {
                self.swiftModel.schemaSelected(false);
                self.swiftModel.wireValidationModel = {};
                self.swiftModel.schema({});
                self.swiftModel.schemaFields([]);
                
            }
            var params = "CountryCode=" + self.swiftModel.country();           

            self.getSchemaFromAPI(params,
                function (response) {
                    if (response.isSuccess) {
                        self.swiftModel.schema(response._success.schema);
                        self.swiftModel.schemaSelected(true);
                    }
                });
        }).extend({ deferred: true });


        self.init = function () {
            self.result(undefined);
            self.getCountryAndCurrencyDetails(
                function (response) {
                    self.swiftModel.paymentMandateOptions = response;
                    var unique = {};
                    for (var i in response) {
                        if (response[i].hasOwnProperty("countryCode") &&
                            typeof unique[response[i].countryCode] === "undefined") {
                            if (typeof self.countriesNameWithCode[response[i].countryCode] !== "undefined" && response[i].countryCode != 'GB') {
                                self.countries.push({
                                    "countryCode": response[i].countryCode,
                                    "countryName": self.countriesNameWithCode[response[i].countryCode]
                                });
                            }
                        }
                        unique[response[i].countryCode] = 0;
                    }
                    self.countries.sort(compareString);
                });
        };

        var postMandate = function () {

            self.swiftModel.isSwiftPaymentDetailsValid(false);

            var message = helpers.buildResponseMessage(self.swiftModel.wireValidationModel, self.swiftModel.activeFields);
        

            self.swiftModel.update(false,false);

            const dto = {
                contentToValidate: message, 
                CountryCode: self.swiftModel.country()
            };
            
            return networkApi.Payments.validateSwiftPayment(dto).then(function(response) {
                if (response.isSuccess) {
                    self.swiftModel.isSwiftPaymentDetailsValid(true);
                    AssignDataToOverseasBankDetailsModel(message);                    
                }
                else
                {
                    var errorResult=response._error.errorContent;
                    if (errorResult !== null&& typeof errorResul!==undefined&&errorResult.length>0)
                    {
                        var errorMessage = '';
                        ko.utils.arrayForEach(errorResult, function (error) {

                            if (error.FieldName !== 'BeneficiaryBankAccount') {
                                errorMessage += error.ErrorMessage + ' - ' + error.FieldTitle + '.';
                            }
                            
                        });

                        self.result(errorMessage);
                        
                    }
                    
                }
                return response.isSuccess;

            });
        };

        self.onSubmit = function () {
            self.result(undefined);
            helpers.setAllFeedbackToTrue(self.swiftModel.feedback);

            self.swiftModel.wireValidationModelStatus = ko.validatedObservable(self.swiftModel.wireValidationModel);
            self.swiftModel.wireValidationModelStatus.errors.showAllMessages(true);

            self.swiftModel.country.onChange();      

            if (self.swiftModel.wireValidationModelStatus.isValid() && self.swiftModel.bankErrors().length == 0) {
               return postMandate();
            }
            else {
                self.swiftModel.isSwiftPaymentDetailsValid(false);

                var def = $.Deferred();

                def.resolve(false);

                return def.promise();
            }
        };
        
        self.setValidateSwiftBankDetails(self.onSubmit);

            

        self.getCountryAndCurrencyDetails = function (successCallback) {

            networkApi.Payments.getPaymentMandateOptions().then(function(response)  {

                successCallback(response);

            }, function()  {
                this.actionError(Resources.unknown_error);
            });
        };
        

       
        

        var AssignDataToOverseasBankDetailsModel =function (contentFromPaymentValidationService) {
                      

             for (var jsonPropertyName in contentFromPaymentValidationService) {

                 if (jsonPropertyName === 'beneficiaryBankAccount' || jsonPropertyName === 'beneficiaryDetails') {

                for (var propertyName in contentFromPaymentValidationService[jsonPropertyName]) {

                    if (contentFromPaymentValidationService[jsonPropertyName][propertyName] !== undefined && self.swiftModel.hasOwnProperty(propertyName) && ko.isObservable(self.swiftModel[propertyName])) {

                        self.swiftModel[propertyName](contentFromPaymentValidationService[jsonPropertyName][propertyName]);
                    }
                }
            }
            else
            {
                if (contentFromPaymentValidationService[jsonPropertyName] !== undefined && self.swiftModel.hasOwnProperty(jsonPropertyName) && ko.isObservable(self.swiftModel[jsonPropertyName])) {
                    self.swiftModel[jsonPropertyName](contentFromPaymentValidationService[jsonPropertyName]);
                }
            }
                 

        }

             self.swiftModel.countryName(self.countriesNameWithCode[self.swiftModel.country()]);             


    };

        var compareString = function (a, b) {
            var countryNameA = (a.countryName)? a.countryName.toUpperCase(): null;
            var countryNameB = (b.countryName) ? b.countryName.toUpperCase() : null;
            if (countryNameA < countryNameB) {
                return -1;
            }
            if (countryNameA > countryNameB) {
                return 1;
            }
            
            return 0;
        }


        self.init();
    };
});
