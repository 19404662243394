// ReSharper disable once InconsistentNaming
define('mandatePanel',['knockout', 'schemaFieldCreation', 'knockout.validation', 'customValidationRules'], function (ko, SchemaFieldCreation) {
    return function (params) {
        var self = this;

        self.schemaFields = params.schemaFields;
        self.IsEditMode = params.IsEdit;
        self.schema = params.schema;
        self.model = params.model;
        self.feedback = params.feedback;
        self.activeFields = params.activeFields;
        self.countries = params.countries;

        var schemaFieldCreator = new SchemaFieldCreation(self.model, self.activeFields, self.feedback, self.schema, self.schemaFields);

        self.onLoad = function (sfc) {
            ko.validation.init({
                decorateInputElement: true,
                errorElementClass: "has-error",
                messagesOnModified: true,
                decorateElementOnModified: true,
                grouping: {
                    deep: true,
                    live: true,
                    observable: true
                }

            });

            ko.validation.registerExtenders();

            sfc.createSchemaFields();
        };

        if (!self.IsEditMode())
        {
            self.onLoad(schemaFieldCreator);
        }
        else
        {
            self.IsEditMode(false);
        }
                            
    };
});
